<template>
  <div>
    <div class="text-right mb-1">
      <b-button variant="outline-primary" to="/bulk-upload/inventories">
        <span class="text-nowrap"
          >Bulk Upload <feather-icon icon="FileTextIcon"></feather-icon>
        </span>
      </b-button>
    </div>
    <b-card title="Add New Inventory">
      <b-row>
        <!-- <b-col cols="3">
          <b-form-group label="Item ID" label-for="item-id-input">
            <b-form-input id="item-id-input" placeholder="Enter Item ID" />
          </b-form-group>
        </b-col> -->
        <b-col cols="3">
          <b-form-group label="SKU" label-for="sku-input">
            <b-form-input
              id="sku-input"
              v-model="newInventory.SKU"
              placeholder="Enter SKU"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Category" label-for="category-input">
            <v-select
              placeholder="Select Category"
              v-model="newInventory.category"
              :options="categoryOptions"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Brand" label-for="brand-select">
            <v-select
              @input="fetchModels"
              :loading="loading.brands"
              placeholder="Select Brand"
              v-model="newInventory.brand"
              :options="brands"
              label="name"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Model" label-for="model-select">
            <v-select
              :loading="loading.models"
              placeholder="Select Model"
              v-model="newInventory.model"
              :options="models"
              label="name"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Item Name" label-for="item-name">
            <b-form-input
              id="item-name"
              v-model="newInventory.name"
              placeholder="Enter Item Name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <b-form-group label="Stock (In-Hand)" label-for="stockCount">
                <b-form-spinbutton
                  id="stockCount"
                  v-model="newInventory.stock"
                  min="0"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Stock Warning At"
                label-for="stock-warning-count"
              >
                <b-form-spinbutton
                  id="stock-warning-count"
                  v-model="newInventory.stockWarningAt"
                  min="0"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Unit Price" label-for="unitPrice">
            <b-input-group prepend="₹">
              <b-form-input
                id="unit-price-input"
                v-model="newInventory.unitPrice"
                placeholder="0.00"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Item Condition"
            label-for="item-condition-select"
          >
            <v-select
              placeholder="Select Item Condition"
              v-model="newInventory.itemCondition"
              :options="['New', 'Refurbished', 'Used']"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="9"></b-col>
        <b-col cols="3" class="d-flex align-self-center justify-content-end">
          <b-button @click="createInventory" block variant="primary">
            <b-spinner small v-if="loading.creating"></b-spinner> Create
            Inventory</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-card-title>
        <div class="d-flex justify-content-between">
          <div>
            <h4>
              Inventories
            </h4>
          </div>
          <div>
            <b-button
              @click="showFilters = !showFilters"
              size="sm"
              variant="outline-dark"
            >
              <feather-icon
                :icon="showFilters ? 'XIcon' : 'FilterIcon'"
              ></feather-icon>
              {{ showFilters ? "Hide Filters" : "Show Filters" }}
            </b-button>
          </div>
        </div>
      </b-card-title>

      <div class="filters mb-1" v-if="showFilters">
        <b-row>
          <b-col cols="3">
            <b-form-group label="Item Name" label-for="item-name">
              <v-select
                @search="fetchInventoryItems"
                placeholder="Search Item"
                v-model="filter.name"
                :options="inventoryNames"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Category" label-for="category-input">
              <v-select
                placeholder="Select Category"
                v-model="filter.category"
                :options="categoryOptions"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Brand" label-for="brand-select">
              <v-select
                @input="fetchModels"
                :loading="loading.brands"
                placeholder="Select Brand"
                v-model="filter.brand"
                :options="brands"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Model" label-for="model-select">
              <v-select
                :loading="loading.models"
                placeholder="Select Model"
                v-model="filter.model"
                :options="models"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="9"></b-col>
          <b-col cols="3" class="d-flex align-self-center justify-content-end">
            <b-button
              :disabled="loading.searching"
              @click="searchInventory"
              block
              variant="outline-primary"
            >
              <b-spinner small v-if="loading.searching"></b-spinner>
              <feather-icon icon="SearchIcon"></feather-icon> Search
              Inventory</b-button
            >
          </b-col>
        </b-row>
      </div>

      <b-badge v-if="showLowInStock" variant="light-primary" class="mb-1" pill
        >Low In Stock
        <b-button
          @click="removeLowInStockFilter"
          size="sm"
          variant="outline-primary"
          class="btn-icon rounded-circle ml-1"
        >
          <feather-icon icon="XIcon"></feather-icon> </b-button
      ></b-badge>

      <b-badge v-if="!showLowInStock" variant="light-success" class="mb-1" pill
        >Show Low In Stock
        <b-button
          @click="showLowInStockItems"
          size="sm"
          variant="outline-success"
          class="btn-icon rounded-circle ml-1"
        >
          <feather-icon icon="CheckIcon"></feather-icon> </b-button
      ></b-badge>

      <b-table
        :busy="loading.fetching"
        responsive
        :items="inventories"
        :fields="fields"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading data...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <h4>No Inventory found</h4>
              <feather-icon size="32" icon="AlertTriangleIcon"></feather-icon>
            </b-col>
          </div>
        </template>
        <template #cell(brand)="data">
          {{ data.item.brand.name }}
        </template>
        <template #cell(model)="data">
          {{ data.item.model.name }}
        </template>
        <template #cell(stock)="data">
          <b-badge pill :variant="getStockVariant(data.item)">{{
            data.item.stock
          }}</b-badge>
        </template>
        <template #cell(unit_price)="data">
          Rs {{ formatPrice(data.item.unitPrice) }}
        </template>
        <template #cell(item_condition)="data">
          {{ data.item.itemCondition }}
        </template>

        <template #cell(action)="data">
          <b-button
            @click="editInventory(data.item)"
            v-b-tooltip.hover.v-primary
            title="Edit Inventory"
            size="sm"
            class="btn-icon"
            variant="outline-primary"
          >
            <feather-icon icon="EditIcon"></feather-icon>
          </b-button>
        </template>
      </b-table>

      <b-card-footer>
        <div class="text-center mt-1">
          <b-pagination
            v-model="page"
            :total-rows="totalInventories"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      size="xl"
      no-close-on-backdrop
      v-model="showInventoryEditModal"
      id="inventory-edit-modal"
      content-class="shadow"
      title="Edit Inventory"
      no-fade
      ok-only
      ok-title="Save Changes"
      @ok="updateInventory"
    >
      <b-card-text>
        <b-row>
          <b-col cols="3">
            <b-form-group label="SKU" label-for="sku-input">
              <b-form-input
                id="sku-input"
                v-model="edit.SKU"
                placeholder="Enter SKU"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Category" label-for="category-input">
              <v-select
                placeholder="Select Category"
                v-model="edit.category"
                :options="categoryOptions"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Brand" label-for="brand-select">
              <v-select
                disabled
                @input="fetchModels"
                :loading="loading.brands"
                placeholder="Select Brand"
                v-model="edit.brand"
                :options="brands"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Model" label-for="model-select">
              <v-select
                disabled
                :loading="loading.models"
                placeholder="Select Model"
                v-model="edit.model"
                :options="models"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Item Name" label-for="item-name">
              <b-form-input
                id="item-name"
                v-model="edit.name"
                placeholder="Enter Item Name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Unit Price" label-for="unitPrice">
              <b-input-group prepend="₹">
                <b-form-input
                  id="unit-price-input"
                  v-model="edit.unitPrice"
                  placeholder="0.00"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Item Condition"
              label-for="item-condition-select"
            >
              <v-select
                placeholder="Select Item Condition"
                v-model="edit.itemCondition"
                :options="['New', 'Refurbished', 'Used']"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <b-form-group label="Stock (In-Hand)" label-for="stockCount">
                  <b-form-spinbutton
                    id="stockCount"
                    v-model="edit.stock"
                    min="0"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Stock Warning At"
                  label-for="stock-warning-count"
                >
                  <b-form-spinbutton
                    id="stock-warning-count"
                    v-model="edit.stockWarningAt"
                    min="0"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSpinbutton,
  BInputGroup,
  BSpinner,
  BPagination,
  BCardFooter,
  BBadge,
  BCardTitle,
  VBTooltip,
  BModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import commaNumber from "comma-number";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BFormSpinbutton,
    BInputGroup,
    BSpinner,
    BPagination,
    BCardFooter,
    BBadge,
    BCardTitle,
    BModal,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: {
        brands: false,
        models: false,
        creating: false,
        searching: false,
        fetching: true,
        updating: false,
      },
      showFilters: true,
      fields: [
        "name",
        "brand",
        "model",
        "stock",
        "unit_price",
        "SKU",
        "category",
        "item_condition",
        "action",
      ],
      inventories: [],
      brands: [],
      models: [],
      inventoryNames: [],
      categoryOptions: [
        "Accessories",
        "Backdoor / Housing Set",
        "Battery",
        "Camera / Camera Glass",
        "Charger",
        "Charging Strip",
        "Display",
        "Home Button",
        "Keyboard",
        "Laptop Parts",
        "Mobile",
        "Motherboard",
        "Parts",
        "Power Button",
        "Slim Tray",
        "Speaker / Ringer",
        "Touchpad / Glass",
      ],
      newInventory: {
        itemID: "",
        SKU: "",
        category: "",
        brand: null,
        model: null,
        name: "",
        itemCondition: "New",
        stock: 0,
        stockWarningAt: 0,
        unitPrice: "",
      },
      totalInventories: 0,
      page: 1,
      pageLimit: 25,
      filter: {
        category: "",
        brand: null,
        model: null,
        name: "",
      },
      showInventoryEditModal: false,
      edit: {},
      showLowInStock: false,
    };
  },
  watch: {
    page() {
      this.getInventories();
    },
  },
  methods: {
    formatPrice(price) {
      return commaNumber(price);
    },
    getStockVariant(data) {
      if (data.stock <= data.stockWarningAt) {
        return "danger";
      } else {
        return "success";
      }
    },
    delay(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    searchInventory() {
      const filter = JSON.parse(JSON.stringify(this.filter));
      let { brand, model, name, category } = filter;
      if (brand) {
        brand = brand._id;
      }
      if (model) {
        model = model._id;
      }
      if (name) {
        name = name.name;
      }
      this.loading.searching = true;
      this.getInventories(name, category, brand, model);
    },
    removeLowInStockFilter() {
      this.showLowInStock = false;
      const currentQueryParams = { ...this.$route.query };
      delete currentQueryParams["low-in-stock"];
      this.$router.replace({ query: currentQueryParams });
      this.getInventories();
    },
    showLowInStockItems() {
      this.showLowInStock = true;
      const currentQueryParams = { ...this.$route.query };
      currentQueryParams["low-in-stock"] = true;
      this.$router.replace({ query: currentQueryParams });
      this.getInventories();
    },
    getInventories(
      itemName = null,
      category = null,
      brand = null,
      model = null
    ) {
      let queryParams = [];
      let apiURL = `${process.env.VUE_APP_SERVER_URL}/api/inventory?limit=${this.pageLimit}&page=${this.page}`;
      if (this.showLowInStock) {
        queryParams.push(`stock=low`);
      }
      if (itemName) {
        queryParams.push(`name=${itemName}`);
      }
      if (category) {
        queryParams.push(`category=${category}`);
      }
      if (brand) {
        queryParams.push(`brand=${brand}`);
      }
      if (model) {
        queryParams.push(`model=${model}`);
      }
      if (queryParams.length) {
        queryParams = queryParams.join("&");
        apiURL = `${apiURL}&${queryParams}`;
      }
      axios
        .get(apiURL, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const { inventories, totalInventories } = response.data;
          this.inventories = inventories;
          this.totalInventories = totalInventories;
          this.loading.searching = false;
          this.loading.fetching = false;
        })
        .catch((error) => {
          this.loading.searching = false;
          this.loading.fetching = false;
          console.log(error.response.data);
        });
    },
    fetchInventoryItems(search, loading) {
      loading(true);
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/inventory?name=${search}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.inventoryNames = response.data;
          loading(false);
        })
        .catch((error) => {
          console.log(error.response.data);
          loading(false);
        });
    },
    getBrands() {
      this.loading.brands = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.loading.brands = false;
          this.brands = response.data;
        })
        .catch((error) => {
          this.loading.brands = false;
          console.log(error.response.data);
        });
    },
    getModels(brand) {
      this.loading.models = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/models/${brand}`)
        .then((response) => {
          this.loading.models = false;
          this.models = response.data.models;
        })
        .catch((error) => {
          this.loading.models = false;
          console.log(error.response.data);
        });
    },
    fetchModels(data) {
      this.getModels(data.slug);
    },
    createInventory() {
      let data = JSON.parse(JSON.stringify(this.newInventory));
      data.brand = data.brand._id;
      data.model = data.model._id;
      let errors = [];

      this.loading.creating = true;
      if (!data.brand) {
        errors.push({ msg: "Brand is required!" });
      }
      if (!data.model) {
        errors.push({ msg: "Model is required!" });
      }
      if (!data.name) {
        errors.push({ msg: "Name is required!" });
      }
      if (!data.stock < 0) {
        errors.push({ msg: "Stock is required!" });
      }
      if (data.unitPrice < 0 || !data.unitPrice) {
        errors.push({ msg: "Unit Price is required!" });
      }
      if (!data.itemCondition < 0) {
        errors.push({ msg: "Item Condition is required!" });
      }
      if (errors.length) {
        this.loading.creating = false;
        return this.$displayErrors(errors);
      }
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/inventory/add`, data, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then(async (response) => {
          this.loading.creating = false;
          this.makeToast("success", "Success", `Inventory added successfully!`);
          await this.delay(1000);
          window.location.reload();
        })
        .catch((error) => {
          this.loading.creating = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            return this.$displayErrors(error.response.data.errors);
          }
          this.makeToast("danger", "Error", `Error adding inventory!`);
        });
    },
    editInventory(data) {
      this.edit = data;
      this.showInventoryEditModal = true;
    },
    updateInventory(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading.updating = true;
      let data = JSON.parse(JSON.stringify(this.edit));
      let inventoryID = data._id;

      let errors = [];
      if (!data.brand) {
        errors.push({ msg: "Brand is required!" });
      }
      if (!data.model) {
        errors.push({ msg: "Model is required!" });
      }
      if (!data.name) {
        errors.push({ msg: "Name is required!" });
      }
      if (!data.stock < 0) {
        errors.push({ msg: "Stock is required!" });
      }
      if (data.unitPrice < 0 || !data.unitPrice) {
        errors.push({ msg: "Unit Price is required!" });
      }
      if (!data.itemCondition < 0) {
        errors.push({ msg: "Item Condition is required!" });
      }
      if (errors.length) {
        this.loading.updating = false;
        return this.$displayErrors(errors);
      }

      data.brand = data.brand._id;
      data.model = data.model._id;

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/inventory/update`,
          { updates: data, inventory: inventoryID },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.loading.updating = false;
          this.makeToast(
            "success",
            "Success",
            `Inventory updated successfully!`
          );
          this.showInventoryEditModal = false;
          await this.delay(1000);
          window.location.reload();
        })
        .catch((error) => {
          this.loading.updating = false;
          this.showInventoryEditModal = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            return this.$displayErrors(error.response.data.errors);
          }
          this.makeToast("danger", "Error", `Error updating inventory!`);
        });
    },
  },
  mounted() {
    if (this.$route.query["low-in-stock"]) {
      this.showLowInStock = true;
    }
    this.getInventories();
    this.getBrands();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
